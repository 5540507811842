import { getFlagEmoji } from '@advanza/func'
import { SelectCheckbox, SelectSimple } from '@advanza/input'
import ServicesSelectContainer from 'containers/ServicesSelectContainer'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const ServicesSelect = ({
    serviceIds = [],
    multiple = true,
    countryCode,
    services,
    filter,
    returnObject,
    value,
    useSelectedCountry = true,
    fetchServices,
    placeholder = 'all services',
    ...rest
}) => {
    let ids = filter ? serviceIds.filter(filter) : serviceIds
    const selectedCountries = useSelector((state) => state.countries.countries) || []
    if (countryCode) {
        ids = serviceIds.filter((id) => services[id].country_code === countryCode)
    } else if (useSelectedCountry) {
        ids = serviceIds.filter((id) => selectedCountries.includes(services[id].country_code))
    }
    useEffect(() => {
        fetchServices()
    }, [])
    const items = ids
        .map((id) => {
            const service = services[id]
            return {
                name: service.name + ' ' + getFlagEmoji(service.country_code),
                value: service.service_id,
                countryCode: service.country_code,
                sn: service.name,
            }
        })
        .sort((a, b) =>
            a.countryCode < b.countryCode
                ? 1
                : a.countryCode > b.countryCode
                ? -1
                : a.sn.localeCompare(b.sn)
        )
    if (multiple) {
        return <SelectCheckbox placeholder={placeholder} {...rest} options={items} value={value} />
    }

    return (
        <SelectSimple
            placeholder={placeholder}
            {...rest}
            onChange={(e) =>
                rest.onChangeValue(
                    e.target.value ? [returnObject ? services[e.target.value] : e.target.value] : []
                )
            }
            options={items}
            alwaysControlValue
            value={Array.isArray(value) ? value[0] || null : value}
        />
    )
}

export default ServicesSelectContainer(ServicesSelect)
