import { Select } from '@advanza/advanza_generic'
import { InputField, SelectCheckbox, SelectSimple } from '@advanza/input'
import Modal from '@advanza/modal'
import { Icon, LinearLoader } from '@advanza/ui'
import AdminUsersSelect from 'components/adminUsers/AdminUsersSelect'
import CheckButton from 'components/CheckButton'
import LabelPicker from 'components/labels/LabelPicker'
import AutoRow from 'components/misc/AutoRow'
import HappinessIcon, {
    HAPPINESS_HAPPY,
    HAPPINESS_NEUTRAL,
    HAPPINESS_UNHAPPY,
} from 'components/ui/HappinessIcon'
import SearchLocationInput from 'components/ui/SearchLocationInput'
import { hasAccess } from 'misc/user'
import queryString from 'query-string'
import React, { Fragment } from 'react'
import ProvidersFilterContainer from '../../containers/ProvidersFilterContainer'
import Pagination from '../Pagination'
import ServicesSelect from '../services/ServicesSelect'
import AddProForm from './AddProForm'
import PlatformsSelect from './PlatformsSelect'

class ProvidersFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onCountList = this.onCountList.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.invalidate = this.invalidate.bind(this)
        this._changeFilter = this._changeFilter.bind(this)
        this.onServiceIdsChange = this.onServiceIdsChange.bind(this)
        this.onQueryChange = this.onQueryChange.bind(this)
        this.onFlagsChange = this.onFlagsChange.bind(this)
        this.setStatus = this.setStatus.bind(this)
        this.doAction = this.doAction.bind(this)
        this.changeLocation = this.changeLocation.bind(this)
        this.onChangeLabels = this.onChangeLabels.bind(this)
        this.onPageSizeChange = this.onPageSizeChange.bind(this)
        this.onChangeAdvanzaToggle = this.onChangeAdvanzaToggle.bind(this)
        this.onGhostsToggle = this.onGhostsToggle.bind(this)
        this.onChangeNrNotes = this.onChangeNrNotes.bind(this)

        this.state = {
            query: '',
        }
    }

    getQuery() {
        const parsed = queryString.parse(window.location.search.replace('?', ''), {
            arrayFormat: 'bracket',
        })
        parsed.page = parseInt(parsed.page) || null
        return parsed
    }

    onSetQuery = (diff = {}, replace = false) => {
        const url = new URL(window.location)
        const newOptions = { ...(replace ? {} : this.getQuery()), ...diff }
        newOptions.page = parseInt(newOptions.page) || null
        url.search = queryString.stringify(newOptions, { arrayFormat: 'bracket' })
        window.history.pushState({}, '', url.toString())
    }

    _changeFilter(filterOptions, replace = false) {
        const { filterId, changeFilter } = this.props
        this.onSetQuery(filterOptions, replace)
        changeFilter(filterId, filterOptions, { replace })
    }

    componentDidMount() {
        const { defaultOptions = {}, countries, filter } = this.props
        if (!filter) {
            const options = { ...defaultOptions, ...this.getQuery(), countries }
            if (options.serviceIds) {
                options.serviceIds = options.serviceIds.map(parseInt)
            }
            if (options.query) {
                this.setState({ query: options.query })
            }
            this._changeFilter(options)
        }
        if (!hasAccess('admin')) {
            this._changeFilter({ noGhosts: true })
        }
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (prevProps.countries !== this.props.countries) {
            this._changeFilter({ countries: this.props.countries })
        }
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter({})
        }
    }

    onServiceIdsChange(services) {
        this._changeFilter({
            serviceIds: services,
            page: 0,
        })
    }

    onCountList() {
        this._changeFilter({ countList: true, didInvalidate: true })
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    onFlagsChange(values) {
        this._changeFilter({ flags: values })
    }

    invalidate() {
        this._changeFilter({
            didInvalidate: true,
            page: 0,
        })
    }

    updateQuery() {
        this._changeFilter({
            query: this.state.query,
            page: 0,
        })
    }

    onPageSizeChange(e) {
        const { value } = e.target
        this._changeFilter({ pageSize: value, page: 0 })
    }

    onQueryChange(e) {
        this.setState({ query: e.target.value })
    }

    onChangeNrNotes(e) {
        this._changeFilter({ nrNotes: e.target.value })
    }

    onGhostsToggle(e) {
        this._changeFilter({ noGhosts: e.target.value })
    }

    onChangeAdvanzaToggle(e) {
        this._changeFilter({ onlyAdvanza: e.target.value })
    }

    setStatus(e) {
        const { value: status } = e.target
        const { filterId, setStatus } = this.props
        setStatus(filterId, status)
    }

    doAction(e) {
        const { value: action } = e.target
        const { filterId, doAction } = this.props
        this.setState({ loading: true })
        doAction(filterId, action).then(
            (response) => {
                if (response.modal) {
                    this.setState({ modal: response.modal })
                }
                this.setState({ loading: false })
            },
            () => this.setState({ loading: false })
        )
    }

    changeLocation(location = {}) {
        this._changeFilter({
            municipalityId: location.municipality_id,
            placeName: location.place_name,
            page: 0,
        })
    }

    onChangeLabels(labels, key = 'labelIds') {
        this._changeFilter({
            [key]: labels.map((label) => label.office_label_id),
            page: 0,
        })
    }

    render() {
        const { filter, count, countOnPage, hideFilters = [], countries } = this.props
        const { query } = this.state
        if (!filter) {
            return null
        }
        const hasSelection = filter.selection && filter.selection.length > 0

        const pageNr = filter.page || 0
        return (
            <Fragment>
                {this.state.loading && <LinearLoader fixed />}
                <AutoRow>
                    <InputField
                        placeholder="search"
                        value={query}
                        onChange={this.onQueryChange}
                        onKeyPress={(e) => e.key === 'Enter' && this.updateQuery()}
                    />
                    <ServicesSelect
                        onChangeValue={this.onServiceIdsChange}
                        value={filter.serviceIds || []}
                    />
                    <AdminUsersSelect
                        onChangeValue={(values) => this._changeFilter({ adminUsersLabels: values })}
                        placeholder="Label user"
                        value={filter.adminUsersLabels}
                    />
                    <InputField
                        value={this.state._labelCreatedDateQuery}
                        onChange={(e) => this.setState({ _labelCreatedDateQuery: e.target.value })}
                        onBlur={(e) =>
                            this._changeFilter({ labelCreatedDateQuery: e.target.value })
                        }
                        onKeyPress={(e) =>
                            e.key === 'Enter' &&
                            this._changeFilter({ labelCreatedDateQuery: e.target.value })
                        }
                        placeholder="Label date"
                    />
                    <SelectCheckbox
                        options={[
                            { value: 'all', name: 'All Pros' },
                            { value: 'enabled', name: 'Enabled Pros' },
                            { value: 'disabled', name: 'Disabled Pros' },
                            { value: 'on_hold', name: 'Pros On Hold' },
                            { value: 'signed', name: 'Signed Pros' },
                            { value: 'sales', name: 'Sales' },
                            { value: 'unsigned', name: 'Unsigned' },
                            { value: 'churned', name: 'Churned' },
                            { value: 'unsubscribed', name: 'Unsubscribed Pros' },
                            { value: 'lowerScore', name: 'Score < 8' },
                            { value: 'signupComplete', name: 'Onboarding signup' },
                            { value: 'claimed', name: 'Onboarding finished' },
                            { value: 'isMinMonthSigned', name: 'Signed > 1 month' },
                            { value: 'freeTrial', name: 'Free trial' },
                            { value: 'pausePeriod', name: 'Active pause period' },
                            { value: 'hasRollingDayPause', name: 'Rolling paused days' },
                            { value: 'hasRollingMonthPause', name: 'Rolling paused months' },
                            { value: 'noPaymentInfo', name: 'No payment info' },
                            { value: 'gdpr', name: 'GDPR' },
                            { value: 'manualPauseReasonRegular', name: 'Regular manual pause' },
                            {
                                value: 'manualPauseReasonOverdueInvoice',
                                name: 'Manual pause overdue invoice',
                            },
                            { value: 'snoozePeriod', name: 'Snoozed' },
                            {
                                value: 'onboardingPremiumInterest',
                                name: 'Onboarding premium trial checked',
                            },
                            { value: 'new_import', name: 'New Import Untouched' },
                            { value: 'untouched', name: 'New Pending Untouched' },
                            { value: 'noAvatar', name: 'no Avatar' },
                            { value: 'noPhotos', name: 'no Photos' },
                            { value: 'noKeywords', name: 'no Keywords' },
                            { value: 'noDescription', name: 'no Description' },
                            { value: 'noReviewSnippets', name: 'no ReviewSnippets' },
                            { value: 'lowOnReviews', name: '#Reviews < 2' },
                            { value: 'hasReachedLimit', name: 'Limit reached' },
                            { value: 'hasReachedFreeLimit', name: 'Free Limit reached' },
                            {
                                value: 'hasReachedFreeTrialDayLimit',
                                name: 'Free trial day limit reached',
                            },
                            {
                                value: 'hasReachedFreeTrialLeadsLimit',
                                name: 'Free trial leads limit reached',
                            },
                            { value: 'no_labels', name: 'Not labeled' },
                            { value: 'no_pc_id', name: 'No postal code id' },
                            { value: 'franchises', name: 'Franchises' },
                            { value: 'has_widget', name: 'Has placed widget' },
                            { value: 'ghost', name: 'Ghost profiles' },
                            { value: 'disabled_overdue_invoice', name: 'Disabled Overdue Invoice' },
                            { value: 'has_active_booster', name: 'Has active booster' },
                            { value: 'phone_leads_enabled', name: 'Phone leads enabled' },
                            { value: 'website_clicks_enabled', name: 'Website clicks enabled' },
                        ]}
                        onChangeValue={this.onFlagsChange}
                        value={(filter.flags && filter.flags.length > 0 && filter.flags) || 'all'}
                        className="select-min select-wide select-stealth upper c-blue"
                    />
                    {filter.placeName && (
                        <button onClick={this.changeLocation}>
                            <Icon name="close" />
                        </button>
                    )}
                    <SearchLocationInput
                        placeholder="City"
                        value={filter.placeName}
                        countryCode={countries[0]}
                        onChangeLocation={this.changeLocation}
                        style={{ zIndex: 101 }} // above Select from @advanza/advanza_generic
                    />
                    <SelectSimple
                        options={[
                            { value: 10, name: '10' },
                            { value: 20, name: '20' },
                            { value: 100, name: '100' },
                            { value: 300, name: '300' },
                        ]}
                        onChange={this.onPageSizeChange}
                        value={filter.pageSize || []}
                        placeholder="nr results"
                    />
                    {!hideFilters.includes('nrNotes') && (
                        <CheckButton
                            msg="nr notes"
                            checked={filter.nrNotes}
                            value={!filter.nrNotes}
                            onChange={this.onChangeNrNotes}
                        />
                    )}
                    {hasAccess('admin') && (
                        <CheckButton
                            msg="no ghosts"
                            checked={filter.noGhosts}
                            value={!filter.noGhosts}
                            onChange={this.onGhostsToggle}
                        />
                    )}
                    <CheckButton
                        msg={<img alt="favicon" src="https://advanza.nl/favicon.ico" width="13" />}
                        checked={filter.onlyAdvanza}
                        value={!filter.onlyAdvanza}
                        onChange={this.onChangeAdvanzaToggle}
                    />
                    <LabelPicker
                        selectedLabelIds={filter.labelIds}
                        categories={['serviceProviders', 'badges', 'profileBuilderWizard']}
                        onValueChange={this.onChangeLabels}
                        filter={({ type }) => type !== 'prospect'}
                    />
                    <LabelPicker
                        selectedLabelIds={filter.prospectLabelIds}
                        categories={['serviceProviders']}
                        onValueChange={(labels) => this.onChangeLabels(labels, 'prospectLabelIds')}
                        placeholderAdd="Prospect value"
                        filter={({ type }) => type === 'prospect'}
                    />
                    <SelectCheckbox
                        options={[
                            {
                                value: HAPPINESS_UNHAPPY,
                                name: <HappinessIcon happiness={HAPPINESS_UNHAPPY} />,
                            },
                            {
                                value: HAPPINESS_NEUTRAL,
                                name: <HappinessIcon happiness={HAPPINESS_NEUTRAL} />,
                            },
                            {
                                value: HAPPINESS_HAPPY,
                                name: <HappinessIcon happiness={HAPPINESS_HAPPY} />,
                            },
                            {
                                value: 0,
                                name: <HappinessIcon happiness={undefined} />,
                            },
                        ]}
                        onChangeValue={(values) => this._changeFilter({ happiness: values })}
                        value={filter.happiness || []}
                        placeholder="happiness score"
                    />
                    <PlatformsSelect
                        onChangeValue={(values) => this._changeFilter({ platforms: values })}
                        value={filter.platforms || []}
                    />
                    <SelectCheckbox
                        options={[
                            { value: 'Providers.created ASC', name: <span>Old first</span> },
                            { value: 'total_score DESC', name: 'Score +' },
                            { value: 'total_score ASC', name: 'Score -' },
                        ]}
                        icon="sort"
                        onChangeValue={(values) => this._changeFilter({ sorting: values })}
                        value={filter.sorting || []}
                        placeholder="order"
                    />
                    <InputField
                        placeholder="a-z"
                        name="alphabetFilter"
                        value={filter.alphabetFilter}
                        inputStyle={{ maxWidth: 65 }}
                        onChange={(e) => this._changeFilter({ alphabetFilter: e.target.value })}
                    />
                    <AddProForm refresh={this.invalidate} />
                    <button onClick={this.invalidate}>
                        <Icon>refresh</Icon>
                    </button>
                    <Pagination
                        current={pageNr}
                        pageSize={filter.pageSize}
                        count={count}
                        countOnPage={countOnPage}
                        onCountList={this.onCountList}
                        onPageChange={this.onPageChange}
                    />
                    <button onClick={() => this._changeFilter({}, true)}>Reset</button>
                    {hasSelection && (
                        <Select
                            options={[
                                { title: <Icon green name="power" />, value: 1 },
                                { title: <Icon red name="power_off" />, value: 0 },
                                { title: <Icon red name="pause_circle" />, value: 3 },
                                { title: <Icon orange name="pending" />, value: 2 },
                                { title: <Icon blue name="👻" />, value: 6 },
                                { title: <Icon orange name="fiber_new" />, value: 7 },
                            ]}
                            className="select-min select-stealth "
                            placeholder="Set status"
                            onChange={this.setStatus}
                        />
                    )}
                    {hasSelection && (
                        <Select
                            options={[
                                { value: 'setDefaultRadius', title: 'Set default radius' },
                                {
                                    value: 'requestRunReviewTask',
                                    title: 'Request update aggr. reviews',
                                },
                            ]}
                            className="select-min select-stealth "
                            placeholder="advanced actions"
                            onChange={this.doAction}
                        />
                    )}
                </AutoRow>
                <Modal open={this.state.modal} close={() => this.setState({ modal: null })}>
                    {this.state.modal}
                </Modal>
            </Fragment>
        )
    }
}

export default ProvidersFilterContainer(ProvidersFilter)
