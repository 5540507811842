export const CHANGE_ENTITY = 'CHANGE_ENTITY'

/**
 * Change an entity
 *
 * Specify the entity with the reducer store, name of entities and the key e.g.: 'services', 'questions', 1
 * Merge the entity with the difference object
 *
 * This action will add some values to the entity prefixed with an underscore:
 *  _isTouched: bool whether the entity is different from before the last save. Is set automatically
 *  _beforeSave: object of the entire entity before the last save. Is set automatically
 *  _errors: object e.g.: {name: true|msgId, description: false}. Is set by the EntityComponent
 *  _saving: bool whether: Is set by the EntityComponent
 *
 * these values can be used in your components to show a loading animation for example
 *
 * This function is used by the EntityComponentContainer with the EntityComponent
 * Use this function also in your actions for deleting and adding entities for example
 *
 * */
export function changeEntity({
    // * required:
    store, // the name of the reducer key e.g. 'requests'
    name: entityName, // the common name of the entities e.g. 'requests'
    key, // the id of the entity

    // * optional:
    diff, // the changes to be made in the entity e.g: {name: test2}
    filterId, // the ids of the filters that contain this entity in the result.
    remove, // if true, removes the entity from filterIds
    newKey, // change the key
    dontTouch, // prevents setting the entity to '_isTouched'
    preservePreviousTouch,
}) {
    return {
        type: `CHANGE_ENTITY_${store.toUpperCase()}`,
        diff,
        entityName,
        key,
        dontTouch,
        preservePreviousTouch,
        newKey,
        store,
        filterId,
        remove,
    }
}
/**
 * Invalidate all filters for store.
 *
 * Use this when you add or delete an entity.
 * If you add or delete an entity
 * */
export function invalidateFiltersForStore(store) {
    return { type: `INVALIDATE_${store.toUpperCase()}`, store }
}
