import { InputField, SelectSimple } from '@advanza/input'
import { WhatsappSvg } from '@advanza/misc/WhatsappSvg'
import { Modal } from '@advanza/modal'
import { Button, Divider, Ellipsis, HoverText, Icon, LoadingDots, PreIcon } from '@advanza/ui'
import NiceModal from '@ebay/nice-modal-react'
import ConfirmSendWhatsAppMessageModal from 'components/billing/ConfirmSendWhatsAppMessageModal'
import InvoiceItem from 'components/billing/InvoiceItem'
import MolliePaymentLink from 'components/billing/MolliePaymentLink'
import MonthPicker from 'components/billing/MonthPicker'
import UnpaidInvoiceStatus from 'components/billing/UnpaidInvoiceStatus'
import Card from 'components/Card'
import CheckButton from 'components/CheckButton'
import AutoRow from 'components/misc/AutoRow'
import Pagination from 'components/Pagination'
import ServicesSelect from 'components/services/ServicesSelect'
import TableList from 'components/TableList'
import A from 'components/ui/A'
import Label from 'components/ui/Label'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { format, sub } from 'date-fns'
import { useInvoiceActions, useInvoicesList } from 'hooks/billingHooks'
import { useUrlQuery } from 'hooks/miscHooks'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import BatchMailButton from './BatchMailButton'

const definitions = {
    invoice_id: { name: '#' },
    subscriptionStatus: { name: 'sub status' },
    businessName: { name: 'name' },
    amount_bruto: { name: '€' },
    amount_netto: { name: '€netto' },
    invoice_nr: { name: 'invoice nr' },
    transaction_status: { name: 'status' },
    datetime: { name: 'created' },
    invoice_about_month: { name: 'about month' },
    pay_method: { name: 'pay method' },
    description: { name: 'description' },
    pay_datetime: { name: 'pay' },
    invoice_sent_date: { name: 'mail sent' },
    reminder_sent_date: { name: 'reminder sent' },
    second_reminder_sent_date: { name: 'second reminder sent' },
    final_reminder_sent_date: { name: 'final reminder sent' },
    unpaidStatus: {
        name: <Icon name="monetization_on" fontSize={18} title="unpaid unvoice follow up" />,
    },
    downloadInvoice: { name: <Icon name="get_app" /> },
    processed_batch: { name: <Icon name="import_export" /> },
}

const definitionsBrief = {
    invoice_id: { name: '#' },
    subscriptionStatus: { name: 's' },
    businessName: { name: 'name' },
    amount_netto: { name: '€netto' },
    description: { name: 'description' },
    invoice_nr: { name: 'invoice nr' },
    transaction_status: { name: 'status' },
    datetime: { name: 'created' },
    unpaidStatus: {
        name: <Icon name="monetization_on" fontSize={18} title="unpaid unvoice follow up" />,
    },
    invoice_about_month: { name: 'about month' },
    downloadInvoice: { name: <Icon name="get_app" /> },
    ecurring_link: { name: <Icon name="link" /> },
    sendWhatsapp: {
        name: (
            <div
                title={
                    'send a whatsapp message to the provider, if the invoice is not paid||fulfilled||succeeded'
                }>
                <WhatsappSvg />
            </div>
        ),
    },
}

const statusMap = {
    pending: { icon: 'accessible_forward', bgColor: 'rgb(255,237,144)' },
    open: { icon: 'schedule', bgColor: 'rgb(228,255,255)' },
    queued: { icon: 'schedule', bgColor: 'rgb(228,255,255)' },
    scheduled: { icon: 'schedule', bgColor: 'rgb(228,237,255)' },
    rescheduled: { icon: 'schedule', bgColor: 'rgb(228,237,255)' },
    fulfilled: { icon: 'done_all', bgColor: '#0ea66b', color: '#fff' },
    paid: { icon: 'done_all', bgColor: '#0ea66b', color: '#fff' },
    charged_back: { icon: 'sync_problem', bgColor: 'rgb(255,0,47)', color: '#fff' },
    failed: { icon: 'warning', bgColor: 'rgb(255,0,47)', color: '#fff' },
    payment_reminder_scheduled: {
        icon: 'notification_important',
        bgColor: 'rgb(255,241,197)',
    },
    payment_reminder_sent: {
        icon: 'notification_important',
        bgColor: 'rgb(255,241,197)',
    },
    payment_reminder_overdue: {
        icon: 'hourglass_bottom',
        bgColor: 'rgb(255,197,202)',
    },
    debt_collection: { icon: 'gavel', bgColor: '#262626', color: '#fff' },
    marked_lost: { icon: 'heart_broken', bgColor: '#5900b7', color: '#fff', name: 'haha' },
    sepa_created: { icon: 'soap', bgColor: '#537ddf', color: '#fff' },
}

const subStatusMap = {
    active: { icon: 'play_arrow', color: '#0ea66b' },
    cancelled: { icon: 'delete_forever', color: 'rgb(255,0,47)' },
    suspended: { icon: 'delete_forever', color: 'rgb(255,0,47)' },
    unverified: { icon: 'pending', color: 'rgb(255,128,0)' },
}

function renderRow(id, entities, expanded) {
    const { invoices } = entities
    const invoice = invoices[id]
    const subStatus = subStatusMap[invoice.subscriptionStatus] || subStatusMap.unverified
    const { protocol, hostname } = window.location

    return {
        cols: Object.keys(expanded ? definitions : definitionsBrief).map((key) => {
            switch (key) {
                case 'datetime':
                case 'pay_datetime':
                    return invoice[key] && format(new Date(invoice[key]), 'd MMM')
                case 'invoice_about_month':
                    return format(new Date(invoice[key]), 'MMM yy')
                case 'amount_bruto':
                case 'amount_netto':
                    return '€' + invoice[key].toFixed(2)
                case 'subscriptionStatus':
                    return (
                        <span title={invoice[key]}>
                            <Icon name={subStatus.icon} style={{ color: subStatus.color }} />
                        </span>
                    )
                case 'processed_batch':
                    return (
                        <Icon title="processed bach" name="done_outline" primColor={invoice[key]} />
                    )
                case 'unpaidStatus':
                    return (
                        <UnpaidInvoiceStatus
                            unpaidInvoiceFollowUp={invoice.unpaid_invoice_follow_up}
                        />
                    )
                case 'transaction_status':
                    return (
                        <span title={invoice.transaction_last_updated}>
                            {
                                <Label
                                    {...statusMap[
                                        invoice.debt_collection
                                            ? 'debt_collection'
                                            : invoice.marked_lost
                                            ? 'marked_lost'
                                            : invoice.transaction_status
                                    ]}
                                    text={
                                        invoice.debt_collection ? (
                                            <>
                                                <s>{invoice.transaction_status}</s>
                                                <br /> Debt Coll.
                                            </>
                                        ) : invoice.marked_lost ? (
                                            <b>Lost</b>
                                        ) : (
                                            invoice.transaction_status
                                        )
                                    }
                                />
                            }
                            {invoice.mollie_logs && invoice.mollie_logs.length > 0 && (
                                <>
                                    {' '}
                                    <HoverText trigger={<Icon name="history" />}>
                                        <ul>
                                            {invoice.mollie_logs.map((log) => (
                                                <li key={log.log_id}>{log.message}</li>
                                            ))}
                                        </ul>
                                    </HoverText>
                                </>
                            )}
                        </span>
                    )
                case 'downloadInvoice':
                    return (
                        <A
                            target="_blank"
                            external
                            href={`${protocol}//${hostname}/api/office/invoices/download-invoice/${invoice.invoice_id}`}>
                            <Icon name="picture_as_pdf" />
                        </A>
                    )
                case 'ecurring_link':
                    return <MolliePaymentLink invoice={invoice} />
                case 'invoice_sent_date':
                case 'reminder_sent_date':
                case 'second_reminder_sent_date':
                case 'final_reminder_sent_date':
                    if (invoice[key]) {
                        return format(new Date(invoice[key]), 'd MMM')
                    } else {
                        return invoice[key]
                    }
                case 'pay_method':
                    return invoice[key] === 1 ? 'DD' : 'M'
                case 'canBeCharged':
                    return invoice[key] ? <Icon green name="card_membership" /> : ''
                case 'sendWhatsapp':
                    return invoice.is_eligible_to_receive_whatsapp_reminder ? (
                        <Button
                            disabled={
                                !invoice.is_valid_phone_number ||
                                !invoice.phone_number_is_in_white_list
                            }
                            title={
                                !invoice.is_valid_phone_number
                                    ? 'phone number is not valid'
                                    : !invoice.phone_number_is_in_white_list
                                    ? 'phone number is not in white list, on test it should belong to Niels, Rick, Freek or Dion'
                                    : invoice.invoice_overdue_manual_trigger_already_sent
                                    ? 'message has already been sent for this invoice'
                                    : 'send a payment reminder message to the provider containing a link to his invoice page'
                            }
                            onClick={() => {
                                NiceModal.show(ConfirmSendWhatsAppMessageModal, {
                                    invoiceId: invoice.invoice_id,
                                })
                            }}
                            appearance={
                                invoice.invoice_overdue_manual_trigger_already_sent
                                    ? 'whatsapp_outlined'
                                    : 'whatsapp'
                            }
                            iconBefore={<WhatsappSvg />}
                        />
                    ) : (
                        <></>
                    )
                case 'businessName':
                    return (
                        <Link
                            title={invoice[key]}
                            to={`/service/providers/provider/${invoice.service_provider_id}/company`}>
                            <Ellipsis style={{ maxWidth: 240 }}>{invoice[key]}</Ellipsis>
                        </Link>
                    )
                default:
                    return invoice[key]
            }
        }),
        expandable: (
            <Card expandableStyle>
                <InvoiceItem invoiceId={id} />
            </Card>
        ),
        isLoading: invoice._isLoading,
        backgroundColor: invoice._isLoading
            ? 'rgb(176,176,176)'
            : invoice._actionStatus && invoice._actionStatus === 'success'
            ? 'rgb(187,250,161)'
            : invoice._actionStatus && invoice._actionStatus === 'error'
            ? 'rgb(250,161,171)'
            : null,
    }
}

const InvoicesList = ({ filterId = 'all', defaultOptions = {}, showIncassoLink }) => {
    const { countries } = useSelector((state) => state.countries)
    const { protocol, hostname } = window.location
    const { query: urlQuery, onSetQuery } = useUrlQuery({
        aboutMonth: format(sub(new Date(), { months: 1 }), 'Y-MM'),
        ...defaultOptions,
    })
    const {
        filter = {},
        entities,
        currentPage,
        selectable = true,
        pageNr,
        ...rest
    } = useInvoicesList(filterId, { ...urlQuery, countries })
    const { invoices } = entities

    const changeFilter = (changes) => {
        onSetQuery({ ...changes, selection: [] })
    }
    const rows = {}
    const [openModal, setOpenModal] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [query, setQuery] = useState('')
    const [showCustomDate, setShowCustomDate] = useState(false)
    const {
        createTransactions,
        setTransactionFulfilled,
        setTransactionUnfulfilled,
        cancelTransactions,
        disableOverdueInvoice,
        deleteInvoices,
        addToDebtCollection,
        createWIKLetterLink,
        isLoading,
        response,
        clearResponse,
        createSecondPayment,
        sendToNotify,
        markLost,
    } = useInvoiceActions(filterId)

    currentPage.forEach((id) => {
        rows[id] = renderRow(id, entities, expanded)
    })
    const selection = filter.selection || []
    const hasSelection = selection.length > 0
    const hasCancelableSelection =
        selection.filter(
            (id) =>
                ['scheduled', 'rescheduled', 'pending', 'queued'].indexOf(
                    invoices[id].transaction_status
                ) !== -1
        ).length === selection.length
    const hasFulfilledSelection = selection.every(
        (id) => invoices[id].transaction_status === 'fulfilled'
    )
    const hasDeletableSelection = selection.every((id) => invoices[id].is_deletable)

    useEffect(() => setShowCustomDate(false), [selection])
    useEffect(() => {
        rest.changeFilter({ ...urlQuery, selection: [] })
        if (urlQuery.query) {
            setQuery(urlQuery.query)
        }
    }, [urlQuery])
    const closeModal = () => {
        setOpenModal(false)
        clearResponse()
    }
    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <Divider />
            <AutoRow>
                <MonthPicker
                    value={filter.aboutMonth}
                    onChange={(aboutMonth) => changeFilter({ aboutMonth: aboutMonth ?? '' })}
                    startYearMonth="2019-10"
                />
                <SelectSimple
                    value={filter.status}
                    placeholder="All invoices"
                    alwaysControlValue
                    options={[
                        { name: 'all', value: 'all' },
                        { name: 'Manual All', value: 'manualAll' },
                        { name: 'Manual Open', value: 'manualOpen' },
                        { name: 'Invoice mail not sent', value: 'invoiceMailNotSent' },
                        { name: 'Manual Paid', value: 'manualPaid' },
                        { name: 'failed Reason Storno (MD06)', value: 'failedReasonStorno' },
                        { name: 'failed Reason Storno (AM04)', value: 'failedReasonInsufficient' },
                        { name: 'failed Reason Other', value: 'failedReasonOther' },
                        { name: 'Sent to Notify', value: 'sentToNotify' },
                        { name: 'Not sent to Notify', value: 'notSentToNotify' },
                        { name: 'Ecurring Active', value: 'ecurringActive' },
                        {
                            name: 'Ecurring Active No Transaction',
                            value: 'ecurringActiveNoTransaction',
                        },
                        {
                            name: 'Subscribed But Ecurring Cancelled',
                            value: 'subscribedButEcurringCancelled',
                        },
                        { name: 'Not Mollie Active', value: 'notEcurringActive' },
                        { name: 'Not connected to Mollie', value: 'noEcurring' },
                        { name: 'Debt collection', value: 'debt_collection' },
                        { name: 'Lost', value: 'marked_Lost' },
                        { name: 'Eligible for payout', value: 'payout' },
                        { name: 'No status', value: 'noStatus' },
                        ...Object.keys(statusMap).map((status) => {
                            return {
                                name: status,
                                value: status,
                            }
                        }),
                    ]}
                    onChange={(e) => changeFilter({ status: e.target.value })}
                />
                <CheckButton
                    msg="expanded"
                    checked={expanded}
                    onChange={() => setExpanded(expanded !== true)}
                />
                <CheckButton
                    msg="open"
                    checked={parseInt(filter.open)}
                    onChange={() => changeFilter({ open: filter.open ? 0 : 1 })}
                />
                <CheckButton
                    msg="Has unpaid invoice followup"
                    checked={filter.hasUnpaidInvoiceFollowUp}
                    onChange={() =>
                        changeFilter({
                            hasUnpaidInvoiceFollowUp: filter.hasUnpaidInvoiceFollowUp !== 1 ? 1 : 0,
                        })
                    }
                />
                <CheckButton
                    msg="Not paid"
                    checked={parseInt(filter.notPaid)}
                    onChange={() =>
                        changeFilter({
                            notPaid: filter.notPaid ? 0 : 1,
                        })
                    }
                />
                {rest.count && (
                    <Pagination
                        current={pageNr}
                        pageSize={parseInt(filter.pageSize)}
                        count={parseInt(rest.count)}
                        onPageChange={(page) => changeFilter({ page })}
                    />
                )}
                <InputField
                    icon="search"
                    placeholder="Enter query and press enter"
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            changeFilter({ query })
                        }
                    }}
                />
                <SelectSimple
                    options={[
                        { value: '<0', name: '< 0' },
                        { value: '>0', name: '> 0' },
                        { value: '<100', name: '< 100' },
                        { value: '100 - 500', name: '100 - 500' },
                        { value: '500 - 1000', name: '500 - 1000' },
                        { value: '>1000', name: '> 1000' },
                    ]}
                    onChange={(e) => changeFilter({ invoiceAmount: e.target.value })}
                    value={filter.invoiceAmount}
                    emptyOption
                    alwaysControlValue
                    placeholder="min invoice amount"
                />
                <ServicesSelect
                    onChangeValue={(serviceIds) => changeFilter({ serviceIds })}
                    value={filter.serviceIds || []}
                    placeholder="Select service"
                    emptyOption
                />
                <SelectSimple
                    options={[
                        { value: 20, name: '20' },
                        { value: 100, name: '100' },
                        { value: 200, name: '200' },
                        { value: 250, name: '250' },
                        { value: 300, name: '300' },
                        { value: 999, name: '999' },
                        { value: 9999, name: '9999' },
                    ]}
                    onChange={(e) =>
                        changeFilter({
                            pageSize: e.target.value,
                            PageSizze: e.target.value,
                        })
                    }
                    value={filter.pageSize || 20}
                    placeholder="nr results"
                />
                <button onClick={() => changeFilter({ didInvalidate: true })}>
                    <Icon name="refresh" />
                </button>
                <BatchMailButton
                    selection={hasSelection ? filter.selection : []}
                    filterId={filterId}
                />
                {hasSelection && showIncassoLink && (
                    <a rel="noreferrer" target="_blank" href={createWIKLetterLink()}>
                        <PreIcon icon="request_page">WIK</PreIcon>
                    </a>
                )}
                {hasSelection && (
                    <Button name="default" onClick={() => setOpenModal('transactions')}>
                        <PreIcon icon="payment">Create transactions</PreIcon>
                    </Button>
                )}
                <Modal
                    open={openModal === 'transactions'}
                    backgroundColor="transparent"
                    allowOverflow
                    close={closeModal}>
                    <h3>Create transaction (Mollie) for {selection.length} invoices</h3>
                    <Divider />
                    {response && <pre>{JSON.stringify(JSON.parse(response), null, 2)}</pre>}
                    <Divider />
                    <Button
                        name="borderedPoppins"
                        disabled={isLoading}
                        onClick={createTransactions}>
                        {isLoading ? <LoadingDots color="#fff" /> : 'Create transactions'}
                    </Button>
                </Modal>
                {hasSelection && (
                    <Button name="discard" onClick={() => setOpenModal('secondPayments')}>
                        <PreIcon red icon="gpp_maybe">
                            Create second payments
                        </PreIcon>
                    </Button>
                )}
                <Modal
                    open={openModal === 'secondPayments'}
                    backgroundColor="transparent"
                    allowOverflow
                    close={closeModal}>
                    <h3>
                        Create second payment for invoice because last payment failed (
                        {selection.length} invoices)
                    </h3>
                    <Divider />
                    {response && <pre>{JSON.stringify(JSON.parse(response), null, 2)}</pre>}
                    <Divider />
                    <Button name="discard" disabled={isLoading} onClick={createSecondPayment}>
                        {isLoading ? <LoadingDots color="#fff" /> : 'Create second payments'}
                    </Button>
                </Modal>

                {hasSelection && (
                    <Button name="borderedPoppins" onClick={() => setOpenModal('send2notify')}>
                        <PreIcon icon="monetization_on">Send to Notify</PreIcon>
                    </Button>
                )}
                <Modal
                    open={openModal === 'send2notify'}
                    backgroundColor="transparent"
                    allowOverflow
                    close={closeModal}>
                    <h3>Send to Notify: {selection.length} invoices</h3>
                    <Divider />
                    {response && <pre>{JSON.stringify(JSON.parse(response), null, 2)}</pre>}
                    <Divider />
                    <Button name="borderedPoppins" disabled={isLoading} onClick={sendToNotify}>
                        {isLoading ? <LoadingDots color="#fff" /> : <>Send to Notfiy</>}
                    </Button>
                </Modal>

                {hasSelection && (
                    <Button name="borderedPoppins" onClick={() => setOpenModal('setFullfilled')}>
                        <PreIcon icon="payment">Set transactions as fulfilled</PreIcon>
                    </Button>
                )}
                <Modal open={openModal === 'setFullfilled'} close={closeModal}>
                    <h3>Set transaction to fulfilled for {selection.length} invoices</h3>
                    <Divider />
                    {response && <pre>{JSON.stringify(JSON.parse(response), null, 2)}</pre>}
                    <Divider />
                    <Button onClick={setTransactionFulfilled}>
                        {isLoading ? <LoadingDots color="#fff" /> : 'Set transactions to fulfilled'}
                    </Button>
                </Modal>

                {hasSelection && hasFulfilledSelection && (
                    <Button name="borderedPoppins" onClick={() => setOpenModal('setUnfulfilled')}>
                        <PreIcon icon="undo">Set transactions as unfulfilled</PreIcon>
                    </Button>
                )}
                <Modal open={openModal === 'setUnfulfilled'} close={closeModal}>
                    <h3>Set transaction to unfulfilled for {selection.length} invoices</h3>
                    <Divider />
                    {response && <pre>{JSON.stringify(JSON.parse(response), null, 2)}</pre>}
                    <Divider />
                    <Button onClick={setTransactionUnfulfilled}>
                        {isLoading ? (
                            <LoadingDots color="#fff" />
                        ) : (
                            'Set transactions to unfulfilled'
                        )}
                    </Button>
                </Modal>

                {hasSelection && hasCancelableSelection && (
                    <Button name="delete" onClick={() => setOpenModal('cancelTransactions')}>
                        Cancel transactions
                    </Button>
                )}
                <Modal open={openModal === 'cancelTransactions'} close={closeModal}>
                    <h3>Cancel transaction (Ecurring) for {selection.length} invoices</h3>
                    <Divider />
                    {response && <pre>{JSON.stringify(JSON.parse(response), null, 2)}</pre>}
                    <Divider />
                    <Button name="borderedPoppins" onClick={cancelTransactions}>
                        {isLoading ? <LoadingDots color="#fff" /> : 'Create transactions'}
                    </Button>
                </Modal>
                {hasSelection && (
                    <Button name="delete" onClick={() => setOpenModal('disableOverdueInvoice')}>
                        Disable (overdue invoice)
                    </Button>
                )}
                <Modal open={openModal === 'disableOverdueInvoice'} close={closeModal}>
                    <h3>disableOverdueInvoice {selection.length} invoices</h3>
                    <Divider />
                    {response && <pre>{JSON.stringify(JSON.parse(response), null, 2)}</pre>}
                    <Divider />
                    <AutoRow>
                        <Button name="borderedPoppins" onClick={() => disableOverdueInvoice(false)}>
                            {isLoading ? <LoadingDots color="#fff" /> : 'disableOverdueInvoice'}
                        </Button>
                        <Button name="borderedPoppins" onClick={() => disableOverdueInvoice(true)}>
                            {isLoading ? (
                                <LoadingDots color="#fff" />
                            ) : (
                                'unset disableOverdueInvoice'
                            )}
                        </Button>
                    </AutoRow>
                </Modal>
                {hasSelection && (
                    <Button name="borderedPoppins" onClick={() => setOpenModal('debtCollection')}>
                        <PreIcon icon="local_atm">Add to debt collection</PreIcon>
                    </Button>
                )}
                {hasSelection && (
                    <Button name="borderedPoppins" onClick={() => setOpenModal('markLost')}>
                        <PreIcon icon="heart_broken">Mark lost</PreIcon>
                    </Button>
                )}
                <Modal open={openModal === 'debtCollection'} close={closeModal}>
                    <h3>Add {selection.length} invoices to debt collection</h3>
                    <Divider m />
                    {response && <pre>{JSON.stringify(JSON.parse(response), null, 2)}</pre>}
                    <Divider m />
                    <AutoRow>
                        <Button
                            onClick={() => addToDebtCollection().then(closeModal)}
                            disabled={isLoading}>
                            {isLoading ? (
                                <LoadingDots color="#fff" />
                            ) : (
                                <span>Add to debt collection ({selection.length} invoices)</span>
                            )}
                        </Button>
                        <Button
                            onClick={() => addToDebtCollection(true).then(closeModal)}
                            name="borderedPoppins"
                            disabled={isLoading}>
                            {isLoading ? (
                                <LoadingDots color="#fff" />
                            ) : (
                                <span>
                                    Remove from debt collection ({selection.length} invoices)
                                </span>
                            )}
                        </Button>
                    </AutoRow>
                </Modal>
                <Modal open={openModal === 'markLost'} close={closeModal}>
                    <h3>Mark {selection.length} invoices as lost</h3>
                    <Divider m />
                    {response && <pre>{JSON.stringify(JSON.parse(response), null, 2)}</pre>}
                    <Divider m />
                    <AutoRow>
                        <Button onClick={() => markLost().then(closeModal)} disabled={isLoading}>
                            {isLoading ? (
                                <LoadingDots color="#fff" />
                            ) : (
                                <span>Mark lost ({selection.length})</span>
                            )}
                        </Button>
                        <Button
                            onClick={() => markLost(true).then(closeModal)}
                            name="borderedPoppins"
                            disabled={isLoading}>
                            {isLoading ? (
                                <LoadingDots color="#fff" />
                            ) : (
                                <span>Undo mark lost ({selection.length})</span>
                            )}
                        </Button>
                    </AutoRow>
                </Modal>
                {hasSelection && (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${protocol}//${hostname}/api/office/invoices/download-credit-invoices-sepa/${
                            countries[0]
                        }/?invoiceIds[]=${selection.join('&invoiceIds[]=')}`}>
                        <Button name="borderedPoppins">
                            <PreIcon icon="soap">Credit invoices SEPA</PreIcon>
                        </Button>
                    </a>
                )}
                {hasSelection && hasDeletableSelection && (
                    <Button name="discard" onClick={() => setOpenModal('deleteInvoice')}>
                        <PreIcon red icon="delete">
                            Delete invoice
                        </PreIcon>
                    </Button>
                )}
                <Modal open={openModal === 'deleteInvoice'} close={closeModal}>
                    <h3>Delete {selection.length} invoices</h3>
                    <Divider />
                    {response && <pre>{JSON.stringify(JSON.parse(response), null, 2)}</pre>}
                    <Divider />
                    <Button onClick={() => deleteInvoices().then(closeModal)}>
                        {isLoading ? <LoadingDots color="#fff" /> : 'delete invoice'}
                    </Button>
                </Modal>
            </AutoRow>
            <Divider m />
            <TableList
                {...rest}
                selectable={selectable}
                changeFilter={rest.changeFilter || (() => {})}
                filter={filter}
                rows={rows}
                definitions={expanded ? definitions : definitionsBrief}
            />
        </LoadBoxWrapper>
    )
}

export default InvoicesList
