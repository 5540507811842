import { call } from '@advanza/api'
import { Button, PreIcon } from '@advanza/ui'
import NiceModal from '@ebay/nice-modal-react'
import ManualReclaimButton from 'components/matches/ManualReclaimButton'
import { useToast } from 'components/misc/Toaster'
import TestClickConfirmModal from 'components/providers/TestClickConfirmModal'
import FormattedTime from 'components/ui/FormattedTime'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import WebsiteClicksListContainer from 'containers/WebsiteClicksListContainer'
import { useList } from 'hooks/listHooks'
import { useIsTest } from 'hooks/miscHooks'
import React from 'react'
import websiteClicksSchema from 'schemes/websiteClicksSchema'
import TableList from '../TableList'

const definitions = {
    website_click_id: { name: '#' },
    created: { name: 'date' },
    chargeAmount: { name: '€' },
    reclaim: { name: 'reclaim' },
}

function renderRow(id, entities) {
    const { websiteClicks } = entities
    const websiteClick = websiteClicks[id]

    return {
        cols: Object.keys(definitions).map((key) => {
            switch (key) {
                case 'website_click_id':
                    return <small>{websiteClick[key]}</small>
                case 'created':
                    return <FormattedTime date={websiteClick[key] || ''} />
                case 'chargeAmount':
                    return websiteClick[key] ? (
                        <small>
                            <b>€{parseFloat(websiteClick[key]).toFixed(2)},-</b>
                        </small>
                    ) : (
                        '-'
                    )
                case 'reclaim':
                    return (
                        <ManualReclaimButton
                            lead={websiteClick}
                            id={websiteClick.website_click_id}
                            leadType="websiteClicks"
                        />
                    )
                default:
                    return websiteClick[key]
            }
        }),
    }
}

const WebsiteClicksList = ({ providerId, filterId = 'all' }) => {
    const {
        filter = {},
        currentPage,
        refresh,
        ...rest
    } = useList(
        filterId,
        {
            url: 'office/website-clicks/get-list',
            schema: websiteClicksSchema,
            reducerKey: 'websiteClicks',
        },
        {},
    )

    const { addToast } = useToast()
    const isTest = useIsTest()
    const rows = {}
    currentPage.forEach((id) => {
        rows[id] = renderRow(id, rest.entities)
    })

    const addTestWebsiteClick = async () => {
        try {
            const response = await call(`office/providers/create-test-website-click/${providerId}`)

            if (!response) {
                throw new Error('No response from API')
            }

            if (response.status === 'success') {
                refresh()
                return
            }

            throw new Error(response.Errorstring || 'Unknown error')
        } catch (error) {
            addToast({
                msg: `Error creating test website click: ${error}`,
                red: true,
                icon: 'error',
            })
        }
    }

    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <TableList
                {...rest}
                selectable={rest.selectable}
                filter={filter}
                rows={rows}
                definitions={definitions}
            />
            {isTest && (
                <Button
                    name="text"
                    onClick={() =>
                        NiceModal.show(TestClickConfirmModal, { onConfirm: addTestWebsiteClick })
                    }>
                    <PreIcon primColor icon="add">
                        Add Test Website Click
                    </PreIcon>
                </Button>
            )}
        </LoadBoxWrapper>
    )
}

export default WebsiteClicksListContainer(WebsiteClicksList)
