// return distinct numbers that are in both arrays
export function intersection(a: number[], b: number[]): number[] {
    var setB = new Set(b)
    return [...new Set(a)].filter((x) => setB.has(x))
}

// return an array, even if it's just a single element
export function toArray<T>(input: T | T[]): T[] {
    return Array.isArray(input) ? input : [input]
}
