// @ts-ignore
import { newContext } from 'immutability-helper'

export const getUpdater = () => {
    const update = newContext()

    // Custom immutability helper functions:

    update.extend('$arrDel', function (value: any, original: any) {
        if (!original) {
            return original
        }
        const clone = [...original]
        const index = clone.indexOf(value)
        if (index !== -1) {
            clone.splice(index, 1)
        }
        return clone
    })
    /**
     * Push to array if it isn't already
     * creates the array if subject doesn't exist
     */
    update.extend('$vivPushDistinct', function (values: any, original: any) {
        if (!original) {
            return values
        }
        const clone = [...original]
        values.forEach((val: any) => {
            if (clone.indexOf(val) === -1) {
                clone.push(val)
            }
        })

        return clone
    })
    /**
     * Unshift to array if it isn't already
     * creates the array if subject doesn't exist
     */
    update.extend('$vivUnshiftDistinct', function (values: any, original: any) {
        if (!original) {
            return values
        }
        values.forEach((val: any) => {
            if (original.indexOf(val) === -1) {
                original.unshift(val)
            }
        })

        return original
    })

    update.extend('$deepMerge', function (values: any, original: any) {
        const clone = { ...original }
        Object.keys(values).forEach((key) => {
            if (!clone[key]) {
                clone[key] = values[key]
            } else {
                clone[key] = { ...original[key], ...values[key] }
            }
        })

        return clone
    })
    /**
     * merge with autoVivication
     */
    update.extend('$vivMerge', function (values: any, original: any) {
        const clone = original ? { ...original } : {}
        return { ...clone, ...values }
    })

    return update
}

export interface Action {
    type: string
    [key: string]: any
}

export type ActionHandlerMap<S> = Record<string, (state: S, action: Action) => S>

export const createReducer =
    <S>(initialState: S, actionHandlers: ActionHandlerMap<S>) =>
    (state: S = initialState, action: Action): S =>
        actionHandlers[action.type]?.(state, action) || state
