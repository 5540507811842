import { Col, Row, Section } from '@advanza/advanza_generic'
import PropTypes from 'prop-types'
import React from 'react'
import AdminUserPermissionsContainer from '../../containers/AdminUserPermissionsContainer'
import Card from '../Card'
import CheckButton from '../CheckButton'
import LinearLoader from '../LinearLoader'
import Restricted from '../Restricted'
import Balloon from '../ui/Balloon'

class AdminUserPermissions extends React.Component {
    constructor(props) {
        super(props)
        this.onChangePermission = this.onChangePermission.bind(this)
        this.getPermission = this.getPermission.bind(this)
    }

    componentDidMount() {
        const { fetchPermissions } = this.props
        fetchPermissions()
    }

    onChangePermission(permission, e) {
        const { value, name } = e.target
        const { entity, onChangeEntity } = this.props
        onChangeEntity({
            permissions: entity.permissions.map((oldPermission) => {
                if (oldPermission.permission_id === permission.permission_id) {
                    return {
                        ...oldPermission,
                        _joinData: { ...oldPermission._joinData, [name]: value },
                    }
                }
                return oldPermission
            }),
        })
    }

    getPermission(permissionId) {
        const { entity, onChangeEntity } = this.props
        let permission =
            entity.permissions.filter(
                (permission) => permission.permission_id === permissionId
            )[0] || null
        if (!permission) {
            permission = {
                user_id: entity.id,
                permission_id: permissionId,
                _joinData: {
                    r: false,
                    w: false,
                },
            }
            onChangeEntity({
                permissions: entity.permissions.concat([permission]),
            })
        }

        return permission
    }

    render() {
        const { permissions, entity } = this.props

        return (
            <Restricted location="admin">
                {permissions.length === 0 && <LinearLoader />}
                <div style={{ maxWidth: 500 }}>
                    {permissions.length > 0 &&
                        entity.permissions &&
                        permissions.map((permission, i) => {
                            const id = permission.permission_id
                            const userPermission = this.getPermission(id)
                            const isNextGroup =
                                i === 0 ||
                                permissions[i].permission_group !==
                                    permissions[i - 1].permission_group
                            return (
                                <div key={i} className="fade-in">
                                    {isNextGroup && (
                                        <Section y>
                                            <b>{permission.permission_group}</b>
                                        </Section>
                                    )}
                                    <Row s="min">
                                        <Col className="grow">
                                            <Balloon content={permission.location}>
                                                {permission.description}
                                            </Balloon>
                                        </Col>
                                        <Col c="auto">
                                            <CheckButton
                                                name="r"
                                                msg="read"
                                                checked={userPermission._joinData.r}
                                                value={!userPermission._joinData.r}
                                                onChange={this.onChangePermission.bind(
                                                    this,
                                                    permission
                                                )}
                                            />
                                        </Col>
                                        <Col
                                            c="auto"
                                            style={{
                                                visibility: permission.read_only
                                                    ? 'hidden'
                                                    : 'visible',
                                            }}>
                                            <CheckButton
                                                name="w"
                                                msg="write"
                                                checked={userPermission._joinData.w}
                                                value={!userPermission._joinData.w}
                                                onChange={this.onChangePermission.bind(
                                                    this,
                                                    permission
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                </div>
            </Restricted>
        )
    }
}

AdminUserPermissions.propTypes = {
    entity: PropTypes.object,
    permissions: PropTypes.array,
}

export default AdminUserPermissionsContainer(AdminUserPermissions)
