import { call } from '@advanza/api'
import { Button, LoadingDots } from '@advanza/ui'
import AutoRow from 'components/misc/AutoRow'
import { ReactNode, useState } from 'react'

interface Props {
    serviceId: number
    children?: ReactNode
}

const InvalidateServiceCachesButton = ({
    serviceId,
    children = 'Invalidate Service Caches',
}: Props) => {
    const [isLoading, setIsLoading] = useState(false)

    const createInvalidation = () => {
        setIsLoading(true)
        call(`office/services/invalidate-service-caches/${serviceId}`, { method: 'post' }).finally(
            () => setIsLoading(false)
        )
    }

    return (
        <Button onClick={createInvalidation} appearance="secondary">
            <AutoRow>
                {children}
                {isLoading && <LoadingDots />}
            </AutoRow>
        </Button>
    )
}

export default InvalidateServiceCachesButton
