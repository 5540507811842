import { call } from '@advanza/api'
import { BaseModal, BaseModalFooter, BaseModalMainContent, Button } from '@advanza/ui'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useAppDispatch } from 'hooks/hooks'
import { useState } from 'react'

const ConfirmSendWhatsAppMessageModal = NiceModal.create(({ invoiceId }: { invoiceId: number }) => {
    const modal = useModal()
    const [isSending, setIsSending] = useState(false)
    const dispatch = useAppDispatch()

    function onConfirm() {
        setIsSending(true)
        call(`office/invoices/send-whatsapp-message/${invoiceId}`).then(() => {
            modal.resolve()
            modal.hide()
            dispatch({ type: 'INVALIDATE_INVOICES' })
        })
    }
    return (
        <BaseModal>
            <BaseModalMainContent>
                Are you sure you want to send this message ?
            </BaseModalMainContent>
            <BaseModalFooter>
                <Button
                    disabled={isSending}
                    style={{ float: 'left' }}
                    onClick={() => {
                        modal.resolve()
                        modal.hide()
                    }}
                    appearance={'secondary'}>
                    Cancel
                </Button>
                <Button
                    disabled={isSending}
                    style={{ float: 'right' }}
                    onClick={() => onConfirm()}
                    appearance={'primary'}>
                    Confirm
                </Button>
            </BaseModalFooter>
        </BaseModal>
    )
})

export default ConfirmSendWhatsAppMessageModal
