import { Col, Row } from '@advanza/grid'
import styles from '@advanza/css/vars.css'
import {
    BaseModal,
    BaseModalFooter,
    BaseModalHeader,
    BaseModalMainContent,
    Button,
    PreIcon,
} from '@advanza/ui'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import React from 'react'
import style from 'components/providers/testClickConfirmModal.module.css'

const TestClickConfirmModal = NiceModal.create(({ onConfirm }) => {
    const modal = useModal()

    const onClose = () => {
        modal.resolve()
        modal.hide()
    }

    return (
        <BaseModal className={style.root} open={modal.visible} close={onClose}>
            <BaseModalHeader className={style.headerText}>Confirm Test Click</BaseModalHeader>
            <BaseModalMainContent>
                <PreIcon icon="info" primColor>
                    you sure you want to add a test click?
                </PreIcon>
            </BaseModalMainContent>
            <BaseModalFooter className={style.footer}>
                <Row between="xs" middle="xs">
                    <Col x>
                        <Button appearance="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                    </Col>
                    <Col x>
                        <Button
                            appearance="primary"
                            onClick={() => {
                                onConfirm()
                                onClose()
                            }}>
                            Confirm
                        </Button>
                    </Col>
                </Row>
            </BaseModalFooter>
        </BaseModal>
    )
})

export default TestClickConfirmModal
