import { OptionStatus } from '@advanza/types/Option'
import { Divider } from '@advanza/ui'
import Checkbox from 'components/input/Checkbox'
import ServiceStatsContainer from 'containers/ServiceStatsContainer'
import { Fragment } from 'react'

const CoverageServiceOptions = ({
    type,
    getStatsAndOptions,
    service,
    serviceEntities,
    onChangeValue,
}) => {
    const { options, counts, stats } = getStatsAndOptions(type)
    const questionIds = service.questions.filter((id) => {
        const q = serviceEntities.questions[id]
        if (options.allQuestions) {
            return q.type === '1' || q.type === '2'
        } else {
            return q.filterable
        }
    })

    return (
        <div>
            {questionIds.map((questionId) => {
                const question = serviceEntities.questions[questionId]
                const optionValues = question.options
                    .filter((id) =>
                        options.showHidden
                            ? true
                            : serviceEntities.options[id].status === OptionStatus.ACTIVE,
                    )
                    .map((id) => {
                        const option = serviceEntities.options[id]
                        const count =
                            type === 'requestStats'
                                ? counts.optionCountsAll[id]
                                : stats.options[id] || 1
                        const total = type === 'requestStats' ? stats.nrReq : stats.nrPros
                        const perc = count && Math.round((count / total) * 100)
                        return {
                            value: id,
                            name: option.is_hidden_default ? (
                                <span title="hidden default, always matched">
                                    <em style={{ pointerEvents: 'none', color: 'grey' }}>
                                        {option.value}
                                    </em>{' '}
                                    <b>100%</b>
                                </span>
                            ) : option.status === OptionStatus.ACTIVE ? (
                                <span>
                                    {option.value} {perc && <b>({perc}%)</b>}
                                </span>
                            ) : (
                                <del>
                                    {option.value} {perc && <b>({perc}%)</b>}
                                </del>
                            ),
                        }
                    })
                return (
                    <Fragment key={questionId}>
                        <b>{question.question}</b>
                        <Divider />
                        <Checkbox
                            options={optionValues}
                            onChangeValue={onChangeValue}
                            value={options.optionIds}
                        />
                        <Divider />
                    </Fragment>
                )
            })}
        </div>
    )
}

export default ServiceStatsContainer(CoverageServiceOptions)
