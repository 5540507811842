import { createReducer, getUpdater } from '@advanza/advanza_generic'
import { Key } from '@advanza/types/Key'
import {
    CHANGE_QUESTION_ORDER,
    INVALIDATE_SERVICE,
    OPTION_DELETED,
    QUESTION_DELETED,
    RECEIVE_OPTION_GROUPS,
    REQUEST_OPTION_GROUPS,
    SERVICES_CHANGE_FILTER,
    SERVICES_RECEIVE_LIST,
    SERVICES_RECEIVE_SINGLE,
    SERVICES_REQUEST,
} from 'actions/services'
import Cta from 'interfaces/Cta'
import Service from 'interfaces/Services'
import { changeEntity, changeFilter, receiveList, selectItems } from './sharedReducerHandlers'

const update = getUpdater()

const handlers = {
    [SERVICES_RECEIVE_LIST]: receiveList,
    [SERVICES_CHANGE_FILTER]: changeFilter,
    CHANGE_ENTITY_SERVICES: changeEntity,
    SERVICES_SELECT_ITEMS: selectItems,

    [SERVICES_REQUEST]: (state: any, action: any) =>
        update(state, {
            isFetching: { $set: action.serviceId || true },
        }),
    [SERVICES_RECEIVE_SINGLE]: (state: any, action: any) =>
        update(state, {
            isFetching: { $set: false },
            entities: { $deepMerge: action.entities },
        }),
    [CHANGE_QUESTION_ORDER]: (state: any, action: any) => {
        const service =
            state.entities.services[state.entities.questions[action.questionId].service_id]
        const orderClone = [...service.questions]
        const index = orderClone.indexOf(action.questionId)
        const newOrder = action.upOrDown === 'up' ? index - 1 : index + 1
        orderClone.splice(newOrder, 0, orderClone.splice(index, 1)[0])
        return update(state, {
            entities: {
                services: {
                    [service.service_id]: {
                        questions: { $set: orderClone },
                    },
                },
            },
        })
    },
    [QUESTION_DELETED]: (state: any, action: any) =>
        update(state, {
            entities: {
                services: {
                    [action.serviceId]: {
                        questions: { $arrDel: action.questionId },
                    },
                },
            },
        }),
    [OPTION_DELETED]: (state: any, action: any) =>
        update(state, {
            entities: {
                questions: {
                    [action.questionId]: {
                        options: { $arrDel: action.optionId },
                    },
                },
            },
        }),
    [REQUEST_OPTION_GROUPS]: (state: any) =>
        update(state, {
            optionGroups: {
                isFetching: { $set: true },
            },
        }),
    [RECEIVE_OPTION_GROUPS]: (state: any, action: any) =>
        update(state, {
            optionGroups: {
                isFetching: { $set: false },
                entities: {
                    [action.questionId]: { $set: action.entities },
                },
            },
        }),
    [INVALIDATE_SERVICE]: (state: any) =>
        update(state, {
            didInvalidate: { $set: true },
        }),
}

const initialState = {
    isFetching: false,
    didInvalidate: false,
    optionGroups: { entities: {} },
    entities: {
        services: {} as Record<Key, Service>,
        ctas: {} as Record<Key, Cta>,
    },
    result: [],
    filters: {},
    counts: {},
}

export default createReducer<typeof initialState>(initialState, handlers)
