import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import Modal from '@advanza/modal'
import { changeEntity } from '@advanza/redux_entity'
import { Button, Divider, HoverText, Icon } from '@advanza/ui'
import { fetchNotes } from 'actions/notes'
import Card from 'components/Card'
import AutoRow from 'components/misc/AutoRow'
import { useProvider } from 'hooks/providerHooks'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useActiveCampaign = (providerId) => {
    const dispatch = useDispatch()
    const [isFetching, setIsFetching] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isComplete, setIsComplete] = useState(false)
    const { provider } = useProvider(providerId)

    const sendEvent = (event, button) => {
        let endPoint = 'active-campaign-profile-event'
        setIsFetching(true)
        call('office/providers/' + endPoint + '/' + providerId + '/' + event).then(
            (response) => {
                if (event === 'ob_done' || event === 'ob_missed') {
                    dispatch({ type: 'INVALIDATE_NOTES' })
                }

                if (event === 'ob_done') {
                    if (response.addedOnboardingOwnerLabel && provider) {
                        dispatch(
                            changeEntity({
                                store: 'providers',
                                name: 'providers',
                                key: providerId,
                                diff: {
                                    office_labels: provider.office_labels.concat(
                                        response.addedOnboardingOwnerLabel
                                    ),
                                },
                            })
                        )
                    }
                }

                setIsFetching(false)
                setIsComplete(response)

                if (button?.setIsOpen) {
                    button.setIsOpen(false)
                }
            },
            (reason) => {
                setIsFetching(false)
                setIsError(reason)
            }
        )
    }

    return {
        isFetching,
        isError,
        isComplete,
        sendEvent,
        provider,
    }
}

const ProviderActiveCampaign = ({ providerId, acContact }) => {
    const dispatch = useDispatch()
    const notesState = useSelector((state) => state.notes)

    const { isFetching, isError, isComplete, sendEvent, provider } = useActiveCampaign(providerId)
    const isClicked = isFetching || isError || isComplete

    useEffect(() => {
        if (notesState.didInvalidate) {
            dispatch(fetchNotes(providerId))
        }
    }, [notesState.didInvalidate])

    const buttons = {
        disable: {
            event: 'disable',
            title: 'Confirm disable profile',
            icon: 'power_off',
            help: `Set disabled status and send "profile_disabled_send_mail" event to ActiveCampaign`,
        },
        unsubscribe: {
            event: 'unsubscribe',
            title: 'Confirm unsubscribe & send email',
            icon: 'mail',
            help: `Set disabled status and send "profile_unsubscribed" event to ActiveCampaign, including unsubscription_date. This will send a confirmation mail`,
        },
        ob_done: {
            event: 'ob_done',
            title: 'Confirm onboarding done',
            icon: 'flag',
            help: `Set changes event field to: “OB done”. Triggers after OB email with signature account owner.`,
        },
        ob_missed: {
            event: 'ob_missed',
            title: 'Confirm onboarding missed',
            icon: 'mail',
            help: `Set changes event field to: “OB missed”. Triggers OB missed mailflow with signature account owner.`,
        },
        ob2_start: {
            event: 'ob2_start',
            title: 'OB2 Start',
            icon: 'mail',
            help: `Start OB2 process`,
        },
        force_update: {
            event: 'force_update',
            title: 'Update provider',
            icon: 'update',
            help: `Forces a provider update to ActiveCampaign`,
        },
        force_token: {
            event: 'force_token',
            title: 'Token refresh',
            icon: 'refresh',
            help: `Forces a token refresh to ActiveCampaign`,
        },
        force_reset: {
            event: 'force_reset',
            title: 'Force contact id reset',
            icon: 'refresh',
            help: `Forces a reset of the contactId connection this provider has with ActiveCampaign, tries to match with Provider ID field.`,
        },
    }

    const [isOpens, setIsOpens] = useState({})
    for (let button in buttons) {
        buttons[button]['isOpen'] = isOpens[button]
        buttons[button]['setIsOpen'] = (value) => setIsOpens({ ...isOpens, [button]: value })
    }

    const status = (
        <Fragment>
            {isFetching && <Col x>{<p className="">...working...</p>}</Col>}

            {isError && (
                <Col x>
                    {
                        <p className="error">
                            <Icon name="error" /> Error: {isError.error}
                        </p>
                    }
                </Col>
            )}

            {isComplete && <Col x>{<p className="success">{isComplete.status}</p>}</Col>}
        </Fragment>
    )

    const buttonElement = (button, i = 0) => {
        return (
            <Col key={i} x>
                <HoverText
                    enableClick
                    trigger={
                        <Button
                            disabled={isClicked}
                            onClick={() => button.setIsOpen(true)}
                            style={{ cursor: 'help' }}>
                            <Icon name={button.icon} /> {button.event} profile
                        </Button>
                    }>
                    {button.help}
                </HoverText>
                <Divider />
                <Modal close={() => button.setIsOpen(false)} maxWidth={600} open={button.isOpen}>
                    <div>
                        <h3>{button.title}?</h3>
                        <Divider />
                        <div>{button.help}</div>
                        <Divider />
                        <Row>
                            <Col x>
                                <Button
                                    disabled={isClicked}
                                    onClick={() => sendEvent(button.event, button)}>
                                    <Icon name={button.icon} /> {button.title}
                                </Button>
                                <Divider />
                            </Col>
                        </Row>
                        <Divider />
                        {status}
                    </div>
                </Modal>
            </Col>
        )
    }

    return (
        <div>
            <Card header="ActiveCampaign actions" icon="double_arrow">
                <Divider m />
                {provider.active_campaigns && provider.active_campaigns.length && (
                    <AutoRow>
                        <a
                            target="_blank"
                            style={
                                provider.active_campaigns[0].ac_contact
                                    ? null
                                    : { cursor: 'not-allowed', opacity: '50%' }
                            }
                            href={
                                provider.active_campaigns[0].ac_contact
                                    ? `https://trustoo.activehosted.com/app/contacts/${provider.active_campaigns[0].ac_contact}`
                                    : null
                            }>
                            <Icon name="double_arrow" /> AC{' '}
                            {provider.active_campaigns[0].ac_contact}
                        </a>
                        <a
                            target="_blank"
                            style={
                                provider.active_campaigns[0].hs_contact
                                    ? null
                                    : { cursor: 'not-allowed', opacity: '50%' }
                            }
                            href={
                                provider.active_campaigns[0].hs_contact
                                    ? `https://app-eu1.hubspot.com/contacts/25911028/contact/${provider.active_campaigns[0].hs_contact}`
                                    : null
                            }>
                            <Icon name="device_hub" /> HS {provider.active_campaigns[0].hs_contact}
                        </a>
                    </AutoRow>
                )}
                <Divider m />
                <Row>
                    {[buttons.force_reset, buttons.force_update, buttons.force_token].map(
                        (button, i) => {
                            return buttonElement(button, i)
                        }
                    )}
                </Row>
                <Divider />
            </Card>
            <Divider />

            <Card header="Unsub Emails" icon="mail">
                <Divider />
                <Row>
                    {[buttons.disable, buttons.unsubscribe].map((button, i) => {
                        return buttonElement(button, i)
                    })}
                </Row>
                <Divider />
            </Card>
            <Divider />

            <Card header="CS Emails" icon="mail">
                <Divider />
                <Row>
                    {[buttons.ob_done, buttons.ob_missed].map((button, i) => {
                        return buttonElement(button, i)
                    })}
                </Row>
                <Divider />
            </Card>

            <Divider />
            <Card header="OB2" icon="mail">
                <Divider />
                <Row>
                    {[buttons.ob2_start].map((button, i) => {
                        return buttonElement(button, i)
                    })}
                </Row>
                <Divider />
            </Card>

            {status}
        </div>
    )
}

export default ProviderActiveCampaign
