import { _changeFilter, _fetchItems, selectItems } from 'actions/lists'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function useList(filterId, options, defaultOptions = {}) {
    const dispatch = useDispatch()
    const { reducerKey } = options

    const fetchList = (newOptions) => {
        return _fetchItems(newOptions || filterId, options)
    }

    const changeFilter = (filterOptions) => {
        dispatch(
            _changeFilter(filterId, filterOptions, {
                fetchFunc: fetchList,
                ...options,
            })
        )
    }

    const refresh = () => {
        changeFilter({ ...defaultOptions, didInvalidate: true })
    }

    const select = (ids) => {
        dispatch(selectItems(reducerKey, ids, filterId))
    }
    const {
        entities,
        result,
        isFetching,
        filters,
        counts,
        stats = [],
        extra,
        error,
    } = useSelector((state) => state[reducerKey])
    const filter = filters[filterId] || {}
    useEffect(() => {
        changeFilter(defaultOptions)
    }, [])

    useEffect(() => {
        if (filter.didInvalidate) {
            changeFilter(defaultOptions)
        }
    }, [filter.didInvalidate])

    const searchKey = filter && filter.searchKey
    const count = filter && counts[searchKey]
    const pageNr = (filter && filter.page) || 0
    const pages = result[searchKey] || {}
    const currentPage = pages[pageNr] || []
    return {
        changeFilter,
        entities,
        result,
        select,
        refresh,
        pageNr,
        pages,
        filter,
        count,
        stats,
        extra,
        currentPage,
        isFetching,
        url: options.url,
        error,
    }
}

export const useListPerCountry = (filterId, options, defaultOptions = {}) => {
    const { countries } = useSelector((state) => state.countries)
    const result = useList(filterId, options, {
        ...defaultOptions,
        countries,
    })

    useEffect(() => {
        result.changeFilter({ countries, serviceIds: [], page: 0 })
    }, [countries])

    return result
}
