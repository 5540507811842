import { objectKeys } from '@advanza/func'
import { Col, Row } from '@advanza/grid'
import { Button, Divider, FixedWarningPopup, Icon, PreIcon } from '@advanza/ui'
import { CTA_SPEC, DEFINITIONS, SELECTS } from 'components/services/CtasList'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useEntityShared } from 'hooks/sharedHooks'
import Cta, { CTA_TYPES } from 'interfaces/Cta'
import { Fragment, useState } from 'react'

export const getFields = (cta: Cta) => {
    const fields: Partial<Record<keyof Cta, any>> = {}
    CTA_TYPES[cta.type].forEach((ctaField) => {
        const select = SELECTS[ctaField]
        fields[ctaField] = {
            ...(select
                ? {
                      type: 'selectSimple',
                      placeholder: (
                          <PreIcon icon={select.icon}>{DEFINITIONS[ctaField].name}</PreIcon>
                      ),
                      options: select.options,
                  }
                : {
                      type: 'text',
                      placeholder: DEFINITIONS[ctaField].name,
                      inputPlaceholder: '<DEFAULT>',
                  }),
            legend: true,
        }
    })
    return fields
}

interface Toast {
    isError?: boolean
    id?: number
    message?: string
}

interface Props {
    ctaId: number | string
}

const CtaEntity = ({ ctaId }: Props) => {
    const [toast, setToast] = useState<Toast>({})
    const {
        entity: cta,
        renderInput,
        onSaveEntity,
        onDeleteEntity,
    }: { entity: Cta; renderInput: any; onSaveEntity: any; onDeleteEntity: any } = useEntityShared(
        CTA_SPEC,
        ctaId,
        getFields
    )

    const onSave = () =>
        onSaveEntity().then(
            () => setToast({ id: Date.now(), message: 'Cta saved' }),
            (response: {}) =>
                setToast({ isError: true, id: Date.now(), message: JSON.stringify(response) })
        )

    return (
        <LoadBoxWrapper isLoading={false}>
            <div>
                <Row>
                    {objectKeys(getFields(cta))
                        .filter((ctaField) => SELECTS[ctaField])
                        .map((ctaField) => (
                            <Col key={ctaField} x>
                                {renderInput(ctaField)}
                            </Col>
                        ))}
                </Row>
                {objectKeys(getFields(cta))
                    .filter((ctaField) => !SELECTS[ctaField])
                    .map((ctaField) => (
                        <Fragment key={ctaField}>
                            <Divider m />
                            {renderInput(ctaField)}
                        </Fragment>
                    ))}
                <Divider m />
                <Row end="xs" middle="xs">
                    <Col x>
                        <button onClick={onDeleteEntity}>
                            <Icon name={cta._saving ? 'hourglass_empty' : 'delete_forever'} />
                        </button>
                    </Col>
                    <Col x>
                        <Button onClick={onSave} disabled={!cta._isTouched || cta._saving}>
                            {cta._saving ? '..saving' : 'Save'}
                        </Button>
                    </Col>
                </Row>
            </div>
            {toast.isError && <div style={{ color: 'red' }}>not saved</div>}
            {/*@ts-ignore*/}
            <FixedWarningPopup red={toast.isError} show={toast.id}>
                {toast.message}
            </FixedWarningPopup>
        </LoadBoxWrapper>
    )
}

export default CtaEntity
