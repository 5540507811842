import { toArray, toLowerUnderscore } from '@advanza/func'
import { Diff } from '@advanza/types/Diff'
import { Key } from '@advanza/types/Key'
import { changeEntity } from 'actions/entities'
import { add, changeOrder, remove, save, singularize } from 'actions/sharedActions'
import { useChangeEntity } from 'hooks/entityHooks'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import BaseData from 'interfaces/BaseData'

export type TypedStore = 'services'
export type EntitiesName = 'ctas' | 'services'

export interface Spec<S extends TypedStore, N extends EntitiesName, NR extends EntitiesName> {
    store: S
    name: N
    nameRel?: NR
    singleRel?: boolean
}

export interface NonGenericSpec {
    store: string
    name: string
    nameRel?: string
    singleRel?: boolean
}

export type GetFields = ((entity: BaseData) => {}) | {}

export type SaveOptions = {
    controller?: string
    query?: {}
}

/**
 * A relation to a higher level is required here in spec.nameRel and keyRel.
 * For 'ctas' this would be 'services' as spec.nameRel and the service id for keyRel.
 **/
export const useListShared = <
    S extends TypedStore,
    N extends EntitiesName,
    NR extends EntitiesName
>(
    spec: Spec<S, N, NR>,
    keyRel: Key,
    getFields: GetFields = {}
) => {
    const { store, name, nameRel, singleRel = false } = spec

    if (!nameRel) {
        throw Error('nameRel is required')
    }

    const dispatch = useAppDispatch()
    const {
        isFetching,
        entities: { [nameRel]: entitiesRel, [name]: entitiesMain },
    } = useAppSelector((state) => state[store])

    const entityRel = entitiesRel[keyRel] || {}
    const fieldRel = toLowerUnderscore(singleRel ? singularize(name) : name)
    // @ts-ignore
    const keys = toArray(entityRel[fieldRel] || [])
    const entityArr = keys
        .map((key) => entitiesMain[key])
        .sort((entityA, entityB) =>
            'order_value' in entityA && 'order_value' in entityB
                ? (entityA.order_value || 0) - (entityB.order_value || 0)
                : 0
        )

    type EntityMain = (typeof entitiesMain)[Key]

    return {
        entityArr: entityArr as EntityMain[],
        isFetching,
        someTouched: entityArr.some((entity) => entity._isTouched),
        someSaving: entityArr.some((entity) => entity._saving),
        add: (extraDiff: Diff<EntityMain> = {}) => dispatch(add(spec, extraDiff, keyRel)),
        remove: (key: Key) => dispatch(remove(spec, key)),
        changeEntity: (key: Key, diff: Diff<EntityMain>) =>
            dispatch(
                // @ts-ignore
                changeEntity({
                    store,
                    name,
                    key,
                    diff,
                })
            ),
        changeOrder: (key: Key, modify: number, filter?: (entity: EntityMain) => boolean) =>
            dispatch(changeOrder(spec, key, modify, filter)),
        save: (keyOrKeys: Key | Key[] = keys) => dispatch(save(spec, keyOrKeys, getFields)),
    }
}

export const useEntityShared = (
    spec: NonGenericSpec,
    entityId: Key,
    getFields: GetFields = {},
    options: SaveOptions = {}
) => {
    const dispatch = useAppDispatch()
    return {
        ...useChangeEntity(
            {
                store: spec.store,
                name: spec.name,
                entityId,
                deleteFunc: (key: Key) => remove(spec, key),
            },
            getFields
        ),
        onSaveEntity: () => dispatch(save(spec, entityId, getFields, options)),
    }
}
