import { objectKeys } from '@advanza/func'
import { Col, Row } from '@advanza/grid'
import { Diff } from '@advanza/types/Diff'
import { Key } from '@advanza/types/Key'
import { Button, Divider, Icon, showConfirmModal } from '@advanza/ui'
import { saveService } from 'actions/services'
import ExpandableEntity from 'components/FlexTable/ExpandableEntity'
import OrderButtonArrows from 'components/misc/OrderButtonArrows'
import CtaEntity from 'components/services/CtaEntity'
import TableList from 'components/TableList'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import StepsRow from 'components/ui/StepsRow'
import { useChangeEntity } from 'hooks/entityHooks'
import { useListShared } from 'hooks/sharedHooks'
import Cta, { CTA_TYPES } from 'interfaces/Cta'
import { useState } from 'react'

export const CTA_SPEC = {
    store: 'services',
    name: 'ctas',
    nameRel: 'services',
} as const

export const DEFINITIONS = {
    cta_id: { name: '#' },
    order: { name: 'Order' },
    icon: {
        name: (
            <a
                style={{ color: '#0077cc', textTransform: 'none' }}
                target="_blank"
                rel="noreferrer"
                href="https://fonts.google.com/icons?icon.style=Outlined&icon.set=Material+Icons">
                Icon (or &apos;no&apos;)
            </a>
        ),
    },
    cta_listing_long: { name: 'CTA listing long' },
    cta_listing_short: { name: 'CTA listing short' },
    cta_profile: { name: 'CTA profile' },
    type: { name: 'Type' },
    request: { name: 'Request' },
    cta_listing_type: { name: 'Listing CTA' },
    cta_profile_type: { name: 'Profile CTA' },
    name_form_start_sentence: { name: 'Name form / Start sentence' },
    match2more: { name: 'Match2More' },
    contact_email: { name: 'Contact email' },
    contact_name: { name: 'Contact name' },
    contact_phone: { name: 'Contact phone' },
    contact_screen: { name: 'Contact screen' },
    cta_contact: { name: 'Contact CTA' },
} as const

export const SELECTS = {
    type: {
        icon: 'insert_drive_file',
        options: Object.keys(CTA_TYPES),
    },
    cta_listing_type: {
        icon: 'notes',
        options: ['no', 'button', 'inline'],
    },
    cta_profile_type: {
        icon: 'perm_contact_calendar',
        options: ['no', 'button', 'inline', 'list', 'inline & list'],
    },
} as Partial<Record<keyof Cta, { icon: string; options: string[] }>>

const renderRow = (cta: Cta, changeOrder: (modify: number) => void) => ({
    cols: objectKeys(DEFINITIONS).map((key) => {
        switch (key) {
            case 'cta_id':
                return typeof cta.cta_id === 'string' ? <Icon name="fiber_new" /> : '#' + cta.cta_id
            case 'order':
                return (
                    <OrderButtonArrows
                        clickUp={() => changeOrder(-1)}
                        clickDown={() => changeOrder(1)}
                    />
                )
            case 'icon':
                return cta.icon === 'no' ? (
                    <span className="pl-1">no</span>
                ) : cta.icon ? (
                    <Icon name={cta.icon} />
                ) : (
                    <span className="text-[#bbb]">DEF</span>
                )
            default:
                return (CTA_TYPES[cta.type] as readonly string[]).includes(key)
                    ? cta[key] || <span className="text-[#bbb]">DEF</span>
                    : 'N/A'
        }
    }),
    id: cta.cta_id,
    expandable: (
        <ExpandableEntity>
            <CtaEntity ctaId={cta.cta_id} />
        </ExpandableEntity>
    ),
    expanded: cta._open,
    expandOnClick: false,
    isLoading: cta._saving,
    backgroundColor:
        typeof cta.cta_id === 'string' ? 'lightgreen' : cta._isTouched ? 'pink' : 'transparent',
})

const FIELDS = {
    enable_cta_experiment: {
        type: 'toggle',
        textTrue: 'A/B test',
        textFalse: 'A/B test',
        _safeAfter: true,
    },
} as const

interface Props {
    serviceId: number
}

const CtasList = ({ serviceId }: Props) => {
    const {
        entityArr: ctas,
        isFetching,
        someSaving,
        add,
        remove,
        changeEntity,
        changeOrder,
        save,
    } = useListShared(CTA_SPEC, serviceId)
    const [variant, setVariant] = useState(false)

    const filterTab = (cta: Cta) => cta.variant === variant
    const ctasTab = ctas.filter(filterTab)
    const ctasTabTouched = ctasTab.filter((cta) => cta._isTouched)
    const rows: Record<Key, any> = {}
    ctasTab.forEach(
        (cta) =>
            (rows[cta.cta_id] = renderRow(cta, (modify: number) =>
                changeOrder(cta.cta_id, modify, filterTab)
            ))
    )

    const extraDiff: Diff<Cta> = { variant }
    objectKeys(SELECTS).forEach((ctaField) => (extraDiff[ctaField] = SELECTS[ctaField]!.options[0]))

    const { renderInput } = useChangeEntity(
        {
            store: 'services',
            name: 'services',
            entityId: serviceId,
            saveFunc: (serviceId: number) => saveService(serviceId, ['enable_cta_experiment']),
        },
        FIELDS
    )

    return (
        <LoadBoxWrapper isLoading={isFetching}>
            {renderInput('enable_cta_experiment')}
            {/*@ts-ignore*/}
            <StepsRow
                items={[
                    {
                        name: 'Current Custom CTAs',
                        id: false,
                    },
                    {
                        name:
                            'Variant Custom CTAs' +
                            (!ctas.some((cta) => cta.variant) ? ' (empty)' : ''),
                        id: true,
                    },
                ]}
                selected={variant}
                onChange={(id: boolean) => setVariant(id)}
            />
            <Divider s />
            <TableList
                pages={{ 0: ctasTab.map((cta) => cta.cta_id) }}
                rows={rows}
                definitions={DEFINITIONS}
            />
            <Divider m />
            <Row middle="xs">
                <Col x>
                    <Button
                        appearance="text_primary"
                        iconBefore="add"
                        disabled={isFetching || someSaving}
                        onClick={() => add(extraDiff)}>
                        Add custom CTA
                    </Button>
                </Col>
                {variant && (
                    <Col x>
                        <Button
                            appearance="text_primary"
                            iconBefore="file_copy"
                            disabled={isFetching || someSaving}
                            onClick={() =>
                                ctas
                                    .filter((cta) => !cta.variant)
                                    .forEach((cta) =>
                                        add({
                                            ...cta,
                                            cta_id: Math.random().toString(36).substring(2, 7),
                                            variant: true,
                                            _open: false,
                                        })
                                    )
                            }>
                            Add copy from current
                        </Button>
                    </Col>
                )}
                {variant && (
                    <Col x>
                        <Button
                            appearance="text_primary"
                            iconBefore="save"
                            disabled={isFetching || someSaving}
                            onClick={async () => {
                                const confirmed = await showConfirmModal({
                                    header: 'Are you sure?',
                                    content: (
                                        <>
                                            You are about to overwrite the current custom CTAs with
                                            this variant. This action cannot be undone. Are you sure
                                            you want to proceed?
                                        </>
                                    ),
                                    cancelContent: 'No, cancel',
                                    confirmContent: 'Yes, save new CTAs',
                                })

                                if (confirmed) {
                                    await Promise.all(
                                        ctas
                                            .filter((cta) => !cta.variant)
                                            .map((cta) => remove(cta.cta_id))
                                    )
                                    await save(
                                        ctas
                                            .filter((cta) => cta.variant)
                                            .map((cta) => {
                                                changeEntity(cta.cta_id, { variant: false })
                                                return cta.cta_id
                                            })
                                    )
                                }
                            }}>
                            Save as current
                        </Button>
                    </Col>
                )}
                <Col x>
                    <Button
                        appearance="text_primary"
                        iconBefore="save"
                        disabled={isFetching || someSaving || !ctasTabTouched.length}
                        onClick={() => save(ctasTabTouched.map((cta) => cta.cta_id))}>
                        Save all on tab
                    </Button>
                </Col>
            </Row>
        </LoadBoxWrapper>
    )
}

export default CtasList
